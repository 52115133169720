
.header {
    background: linear-gradient(270deg, #BD7599 6.77%, #673B6F 105.68%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner {
    background-image: url('../../../../public/assets/banner.svg');
}


.step {
    background: linear-gradient(white, white) padding-box,
    linear-gradient(270deg, #F5A3B5 6.77%, #B86B96 70.51%, #5A1466 105.68%) border-box;
    border: 3px solid transparent;
}

.stepNumber {
    background: linear-gradient(270deg, #F5A3B5 6.77%, #B86B96 70.51%, #5A1466 105.68%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}