@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Spartan";
    src: url('../public/fonts/League_Spartan/LeagueSpartan-VariableFont_wght.ttf')
}

@font-face {
    font-family: "Panton";
    src: url('../public/fonts/Panton_Rust/PantonRustSmBold-Trial-Gr.ttf');
}

@font-face {
    font-family: "Panton";
    src: url('../public/fonts/Panton_Rust/PantonRustHeavy-Trial-Gr.ttf');
    font-weight: bold;
}


body {
    font-family: 'Spartan', sans-serif;
    background-color: #F9F6EE;
}

.header {
    background: linear-gradient(270deg, #BD7599 6.77%, #673B6F 105.68%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.borderImageBottom {
    border: solid transparent;
    border-width: 4px;
    border-image: url('../public/assets/border.png') 45% round;
}

.borderImage {
    border: solid transparent;
    border-width: 6px;
    border-image: url('../public/assets/border_2.png') 20 round;
}