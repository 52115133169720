.greenGradient {
    position: relative;
    background-color: transparent;
}

.greenGradient::before  {
    content: "";
    position: absolute;
    height: 110%;
    transform: translateY(-2.5%);
    width: 100%;
    background-image: url('../../../../public/assets/green_background.png');
    background-size: cover;
    background-position: center;
}
.blueGradient {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, #E5FCF2 26%,#A2FED6 50%, #E5FCF2 74%, rgba(255,255,255,1) 100%)
}

.orangeGradient {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, #FDE6DC 26%,#FFB899 50%, #FDE6DC 74%, rgba(255,255,255,1) 100%)
}

.yellowGradient {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, #FDF4E0 26%, #F4DDAA 50%, #FDF4E0 74%, rgba(255,255,255,1) 100%)
}