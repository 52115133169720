.success {
    background-color: #61E2A4;;
    font-size: 20px;
    padding: 1%;
    text-align: center;
}

.failure {
    background-color: red;
    color: white;
    font-size: 20px;
    padding: 1%;
    text-align: center;
}